<template>
  <div class="positions"
       v-loading.fullscreen.lock="fullscreenLoading">
    <!-- =============弹出层============= -->

    <transition name="el-fade-in-linear">
      <div class="print_main"
           v-if="printShow">
        <div class="print_main_top">
          <el-button type="primary"
                     v-print="printObj">开始打印</el-button>
          <el-button type="danger"
                     @click="cancelPrint">取消打印</el-button>
        </div>

        <!-- 区域/hj全部打印 -->
        <div class="print_main_view"
             v-if="skip_type == 'all'"
             id="printJS-form">
          <span v-for="(item_hj,index_hj) in warehouseData[print_selected.area].children"
                class="print_main_hj"
                :key="index_hj+'hj'">
            <span v-for="(item_c,index_c) in item_hj.children"
                  class="print_main_c"
                  :key="index_c+'c'">
              <div v-for="(item,index) in item_c.children"
                   class="printTier"
                   :key="index+'c'">
                <div class="item_top">
                  {{item.hjcode}}
                </div>
                <div class="item_main">
                  <div class="main_left">
                    <span>{{`${ warehouseData[print_selected.area].code}区域 -${item_hj.code}号货架 `}}</span>
                    <span>{{` ${item_c.code}层 - ${item.code}格`}}</span>
                  </div>
                  <div class="main_right">
                    <vue-qr :text='item.hjcode'
                            :margin="0"
                            colorDark="#000"
                            colorLight="#fff"
                            :logoScale="0.3"
                            :size='140'></vue-qr>
                  </div>
                </div>
                <div class="item_bottom">
                  <svg :id='item.hjcode'>{{barcode(item.hjcode,item.hjcode)}}</svg>
                </div>
              </div>
            </span>
          </span>
        </div>
        <!-- 只区域号打印  area-->
        <div class="print_main_view"
             id="printJS-form"
             v-if="skip_type=='area'">
          <div class="printTier">
            <div class="printTier_top">
              <span>{{warehouseData[print_selected.area].code}}</span>
            </div>
            <div class="printTier_bottom">
              <div>
                区域说明
              </div>
              <span>{{warehouseData[print_selected.area].info}}</span>
            </div>
          </div>
        </div>

        <!-- 货架内容全部打印  hj-->
        <div class="print_main_view"
             v-if="skip_type == 'hj'"
             id="printJS-form">
          <span v-for="(item_c,index_c) in warehouseData[print_selected.area].children[print_selected.hj].children"
                class="print_main_hj"
                :key="index_c+'hj'">
            <div v-for="(item,index) in item_c.children"
                 class="printTier"
                 :key="index+'c'">
              <div class="item_top">
                {{item.hjcode}}
              </div>
              <div class="item_main">
                <div class="main_left">
                  <span>{{`${ warehouseData[print_selected.area].code}区域 -${warehouseData[print_selected.area].children[print_selected.hj].code}号货架 `}}</span>
                  <span>{{` ${item_c.code}层 - ${item.code}格`}}</span>
                </div>
                <div class="main_right">
                  <vue-qr :text='item.hjcode'
                          :margin="0"
                          colorDark="#000"
                          colorLight="#fff"
                          :logoScale="0.3"
                          :size='140'></vue-qr>
                </div>
              </div>
              <div class="item_bottom">
                <svg :id='item.hjcode'>{{barcode(item.hjcode,item.hjcode)}}</svg>
              </div>
            </div>
          </span>
        </div>
        <!-- 只区域号打印  hjcode-->
        <div class="print_main_view"
             id="printJS-form"
             v-if="skip_type=='hjcode'">
          <div class="printTier">
            <div class="printTier_top_hj">
              <span>{{ warehouseData[print_selected.area].children[print_selected.hj].code}}号货架</span>
            </div>

          </div>
        </div>

        <!-- 选择打印 code-->
        <div class="print_main_view"
             id="printJS-form"
             v-if="skip_type=='code'">
          <div v-for="(item,index) in print_selected.hjcode"
               class="printTier"
               :key="index+'c'">
            <div class="item_top">
              {{item}}
            </div>
            <div class="item_main">
              <div class="main_left">
                <span>
                  {{`${ item.split('-')[0]}区域 -${item.split('-')[1]}号货架 `}}</span>
                <span>{{` ${item.split('-')[2]}层 - ${item.split('-')[3]}格`}}</span>
              </div>
              <div class="main_right">
                <vue-qr :text='item'
                        :margin="0"
                        colorDark="#000"
                        colorLight="#fff"
                        :logoScale="0.3"
                        :size='140'></vue-qr>
              </div>
            </div>
            <div class="item_bottom">
              <svg :id='item'>{{barcode(item,item)}}</svg>
            </div>
          </div>
        </div>

      </div>
    </transition>

    <el-dialog title="选择打印"
               v-model="print_show"
               width="1100px">
      <div class="printView">

        <el-tabs v-model="first">
          <el-tab-pane label="区域打印"
                       name="first">
            <el-form label-position='top'
                     width='200px'>
              <el-form-item label="请选择您要打印的区域:">
                <div class="print_select_area">
                  <el-radio-group v-model="print_selected.area">
                    <el-radio-button :label="index"
                                     v-for="(item,index) in warehouseData"
                                     :key="index">{{item.code}}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item label-width="200"
                            label=""
                            v-if="print_selected.area!==''">
                <br>
                <div class="print_select_btn">
                  <el-tooltip effect="dark"
                              content="只打印区号,不打印其中的货架/层格信息"
                              placement="top">
                    <el-button type="primary"
                               @click="print_state_show('area')">打 印 区 号</el-button>
                  </el-tooltip>
                  <el-tooltip effect="dark"
                              content="打印其中所有的层格,但是不打印区号"
                              placement="top">
                    <el-button type="primary"
                               @click="print_state_show('all')">打 印 内 容</el-button>
                  </el-tooltip>
                </div>

              </el-form-item>

            </el-form>
          </el-tab-pane>
          <el-tab-pane label="货架打印"
                       name="second">
            <el-form label-position='top'
                     width='200px'>
              <el-form-item label="请选择您要打印的区域:">
                <div class="print_select_area">
                  <el-radio-group v-model="print_selected.area">
                    <el-radio-button :label="index"
                                     v-for="(item,index) in warehouseData"
                                     :key="index">{{item.code}}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item v-if="print_selected.area!==''"
                            label="请选择货架号:">
                <div class="print_select_hj">
                  <el-radio-group v-model="print_selected.hj">
                    <el-radio-button :label="index"
                                     v-for="(item,index) in warehouseData[print_selected.area].children"
                                     :key="index">{{item.code}}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item v-if="print_selected.hj!==''">
                <br>
                <div class="print_select_btn">
                  <el-tooltip effect="dark"
                              content="只打印货架号,不打印其中的层格信息"
                              placement="top">
                    <el-button type="primary"
                               @click="print_state_show('hjcode')">打 印 货 架</el-button>
                  </el-tooltip>
                  <el-tooltip effect="dark"
                              content="打印其中所有的层格,但是不打印货架号"
                              placement="top">
                    <el-button type="primary"
                               @click="print_state_show('hj')">打 印 内 容</el-button>
                  </el-tooltip>
                </div>
              </el-form-item>

            </el-form>
          </el-tab-pane>
          <el-tab-pane label="选择打印"
                       name="third">
            <el-form label-position='top'
                     width='200px'>
              <el-form-item label="请选择您要打印的区域:">
                <div class="print_select_area">
                  <el-radio-group v-model="print_selected.area">
                    <el-radio-button :label="index"
                                     v-for="(item,index) in warehouseData"
                                     :key="index">{{item.code}}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item v-if="print_selected.area!==''"
                            label="请选择货架号:">
                <div class="print_select_hj">
                  <el-radio-group v-model="print_selected.hj">
                    <el-radio-button :label="index"
                                     v-for="(item,index) in warehouseData[print_selected.area].children"
                                     :key="index">{{item.code}}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item v-if="print_selected.hj!==''"
                            label="请选择要打印的内容:">
                <div class="print_select_hj">
                  <el-checkbox-group v-model="print_selected.hjcode"
                                     size="mini">
                    <div v-for="(item) in warehouseData[print_selected.area].children[print_selected.hj].children"
                         :key="item.id">
                      <el-checkbox :label="item_g.hjcode"
                                   v-for="(item_g) in item.children"
                                   :key="item_g.id"
                                   border> </el-checkbox>
                    </div>

                  </el-checkbox-group>
                </div>
              </el-form-item>

              <el-form-item v-if="print_selected.hjcode[0]">
                <div class="print_select_code">
                  <el-button type="primary"
                             @click="print_state_show('code')">打 印 </el-button>
                </div>
              </el-form-item>

            </el-form>
          </el-tab-pane>
        </el-tabs>

      </div>

    </el-dialog>

    <el-dialog title="创建区域"
               v-model="autoPop"
               width="40%">
      <el-form :model="formData"
               ref="formData"
               label-position='left'
               label-width="100px"
               class="demo-ruleForm">

        <el-form-item label="区域名:"
                      label-width="150"
                      prop="area"
                      :rules="[
      { required: true, message: '区域不能为空哦.'},
    ]">
          <el-input v-model="formData.area"
                    placeholder="区域名称"></el-input>
        </el-form-item>

        <el-form-item label="请输入货架数量:"
                      label-width="150"
                      prop="number"
                      :rules="[
      { required: true, message: '货架数量不能为空.'},
    ]">

          <el-input-number v-model="formData.number"
                           size='mini'
                           :min="1"
                           :max="10"></el-input-number>
        </el-form-item>

        <el-form-item label="请输入货架层数:"
                      label-width="150"
                      prop="tier"
                      :rules="[
      { required: true, message: '货架层数不能为空.'},
    ]">
          <el-input-number v-model="formData.tier"
                           size='mini'
                           :min="1"
                           :max="10"></el-input-number>
        </el-form-item>

        <el-form-item label="请输入每层格数:"
                      label-width="150"
                      prop="grid"
                      :rules="[
      { required: true, message: '货架数量不能为空.'},
    ]">
          <el-input-number v-model="formData.grid"
                           size='mini'
                           :min="1"
                           :max="10"
                           label="描述文字"></el-input-number>
        </el-form-item>

        <el-form-item label="请输入区域说明:"
                      label-width="150"
                      style="width:100%"
                      prop="info"
                      :rules="[
      { required: true, message: '区域说明不能为空哦'},
    ]">
          <el-input style="width:100%"
                    type="textarea"
                    :rows="2"
                    maxlength="30"
                    show-word-limit
                    v-model="formData.info">
          </el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="autoPop = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitForm('formData')">创 建</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog :title="'货格管理 ( '+ gridAdmin.data.hjcode +' )'"
               v-model="gridAdmin.show"
               width="500px">
      <div class="grid_pop_view">
        <el-form :model="gridAdmin.data"
                 ref="gridAdmin"
                 label-position='top'>
          <el-form-item label="最大容量( 为0不限制 )"
                        prop="num"
                        :rules="[
      { required: true, message: '最大容量不能为空'},
    ]">
            <el-input-number v-model="gridAdmin.data.num"
                             :min="0"
                             :max="10"
                             size="mini"
                             label="最大容量"></el-input-number>
          </el-form-item>

          <el-form-item label="存放订单数量">
            <span style="color:#4c90fd">{{gridAdmin.data.number||0}} 个订单</span>
          </el-form-item>

        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="gridAdmin.show = false">取 消</el-button>
          <el-button type="danger"
                     @click="removeGrid()">删除</el-button>
          <el-button type="primary"
                     :disabled='!gridAdmin.data.number'
                     @click="hjcodeGetGoods(gridAdmin.data.hjcode)">查看订单</el-button>
          <el-button type="primary"
                     @click="gridEdit()">修 改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 分开写，以后可能差异化 -->
    <el-dialog title="添加货格"
               v-model="gridAdd.show"
               @close='closeGridAdd'
               width="500px">

      <el-form :model="gridAdd"
               ref="gridAdd"
               label-position='top'>
        <el-form-item label="最大容量-件( 为0不限制 )"
                      prop="num"
                      :rules="[
      { required: true, message: '最大容量不能为空'},
    ]">
          <el-input-number v-model="gridAdd.num"
                           :min="0"
                           :max="10"
                           size="mini"></el-input-number>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="gridAdd.show = false">取 消</el-button>
          <el-button type="primary"
                     @click="addGrid()">创 建</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="添加货架层"
               v-model="tierAdd.show"
               @close='closeTierAdd'
               width="500px">

      <el-form :model="tierAdd"
               ref="tierAdd"
               label-position='top'>
        <el-form-item label="最大包裹容量 ( 为0不限制 ) "
                      prop="num"
                      :rules="[
      { required: true, message: '最大容量不能为空'},
    ]">
          <el-input-number v-model="tierAdd.num"
                           :min="0"
                           :max="10"
                           size="mini"></el-input-number>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="tierAdd.show = false">取 消</el-button>
          <el-button type="primary"
                     @click="addTier()">创 建</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="添加货架"
               v-model="goodsAdd.show"
               @close='closeGoodsAdd'
               width="500px">

      <el-form :model="goodsAdd"
               ref="tierAdd"
               label-position='top'>
        <el-form-item label="最大包裹容量 ( 为0不限制 ) "
                      prop="num"
                      :rules="[
      { required: true, message: '最大容量不能为空'},
    ]">
          <el-input-number v-model="goodsAdd.num"
                           :min="0"
                           :max="10"
                           size="mini"></el-input-number>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="goodsAdd.show = false">取 消</el-button>
          <el-button type="primary"
                     @click="addGoods()">创 建</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="货架设置"
               v-model="goodsSet.show"
               width="500px">
      <el-form :model="goodsSet.data"
               ref="tierAdd"
               label-position='top'>
        <el-form-item label="最大包裹容量 ( 为0不限制 ) "
                      prop="num"
                      :rules="[
      { required: true, message: '最大容量不能为空'},
    ]">
          <el-input-number v-model="goodsSet.data.num"
                           :min="0"
                           :max="10"
                           size="mini"></el-input-number>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="goodsSet.show = false">取 消</el-button>
          <el-button type="danger"
                     @click="dalGoods()">删除</el-button>
          <el-button type="primary"
                     @click="editGoods()">修 改</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog :title="'区域设置 '+areaSet.data.code"
               v-model="areaSet.show"
               width="500px">
      <el-form :model="areaSet.data"
               ref="tierAdd"
               label-position='top'>
        <el-form-item label="最大包裹容量 ( 为0不限制 ) "
                      prop="num"
                      :rules="[
      { required: true, message: '最大容量不能为空'},
    ]">
          <el-input-number v-model="areaSet.data.num"
                           :min="0"
                           :max="10"
                           size="mini"></el-input-number>
        </el-form-item>

        <el-form-item label="区域说明"
                      style="width:100%">
          <el-input type="textarea"
                    autosize
                    placeholder="请输入内容"
                    v-model="areaSet.data.info">
          </el-input>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="areaSet.show = false">取 消</el-button>
          <el-button type="danger"
                     @click="dalArea()">删除</el-button>
          <el-button type="primary"
                     @click="handleInputConfirm()">修 改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- =============弹出层end============= -->
    <div class="positions_view"
         v-loading="loading">
      <div class="positions_view_top">
        <div class="positions_view_inqure">
          <el-form :model="inquireData"
                   ref="inquireData"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="物流单号：">
              <div class="form_view">
                <el-input v-model.number="inquireData.wlnum"
                          @keyup.enter="wlnumCode"
                          placeholder='请输入物流单号'></el-input>
                <el-button type="primary"
                           size="mini"
                           @click="wlnumCode">查询</el-button>
              </div>

            </el-form-item>

            <el-form-item label="货架号：">
              <div class="form_view">
                <el-input v-model="inquireData.hjcode"
                          @keyup.enter="hjcodeInquire"
                          placeholder='请输入货架号'></el-input>
                <el-button type="primary"
                           size="mini"
                           @click="hjcodeInquire">查询</el-button>
              </div>

            </el-form-item>
          </el-form>
        </div>
        <div class="positions_view_btn">
          <el-button type="success"
                     @click="autoPop=true">添加区域</el-button>
          <el-button type="primary"
                     @click="showPrint">打印数据</el-button>
        </div>
      </div>
      <div class="positions_list_null"
           v-if="!warehouseData[0]">
        <el-empty description="还未生成仓位数据">
          <el-button type="primary"
                     size="mini"
                     @click="autoPop=true">生成仓位数据</el-button>
        </el-empty>
      </div>
      <el-tabs v-model="areaActive"
               type="border-card"
               @tab-add='show_new_freight_space'
               @tab-click='active_label'
               class="warehouseData_view"
               v-else>
        <el-tab-pane v-for="(item,index) in warehouseData"
                     :key="index"
                     :name='item.code'>
          <template #label>
            {{item.code}}
            <span class="el-icon-setting right_set"
                  @click.stop="showSetArea(item)">

            </span>
          </template>
          <div class="freightSpace_main_area"
               :class="!item.children?'el-flex ':''">
            <div class="brief">
              <el-input class="input-new-tag"
                        v-if="inputVisible"
                        v-model="item.info"
                        ref="saveTagInput"
                        autofocus
                        @blur="handleInputConfirm(item)"
                        size="small">
              </el-input>
              <el-alert title="区域说明"
                        v-else
                        :closable='false'
                        @click="showInput"
                        type="warning"
                        :description='item.info'>
              </el-alert>
            </div>
            <!-- 非正常数据显示 -->
            <div class="dataJson_null"
                 v-if="!item.children">
              <el-empty description="未检测到货架">
                <el-button type="primary"
                           size="mini"
                           @click="showAddGoods(item.children?item.children[item.children.length-1].code:0,item.id,index)">添加货架</el-button>
              </el-empty>
            </div>
            <!--正常数据显示 -->
            <el-tabs style="width"
                     addable
                     @tab-add='showAddGoods(item.children?item.children[item.children.length-1].code:0,item.id,index)'
                     v-if='item.children'
                     v-model="defuend_tabs">
              <el-tab-pane :name="item_2.code+'test'"
                           v-for="(item_2,index_2) in item.children"
                           :key="index_2">
                <template #label>
                  {{item_2.code}}号货架
                  <span class="el-icon-setting right_set"
                        @click.stop="setContent(item_2)">

                  </span>
                </template>
                <div class="goods_shelf">
                  <!-- 添加层 -->
                  <div class="goods_shelf_item">
                    <el-tooltip effect="dark"
                                content="添加层"
                                :enterable='false'
                                placement="top">
                      <el-button plain
                                 class="add_grid"
                                 style="background-color:#409eff"
                                 @click="showAddTier(item_2.id,index_2)"
                                 size='mini'>
                        <i class='el-icon-plus'></i>
                      </el-button>
                    </el-tooltip>

                  </div>
                  <!-- 添加层 end-->
                  <div class="goods_shelf_item"
                       v-for="(item_3,index_3) in item_2.children"
                       :key="index_3">
                    <el-button v-for="(item_4,index_4) in item_3.children"
                               :ref="item_4.hjcode"
                               :key="index_4"
                               plain
                               :id="item_4.hjcode==inquireData.hjcode?'selectGrid':''"
                               class="grid_data_class"
                               @click="show_grid(item_4)"
                               :class="returnCorresponding(item_4.status,index_3,item_4.num==0)">
                      {{item_4.hjcode}}
                      <!-- <i class="delete _grid el-icon-close "
                         @click="remove_grid(item_3,index_4)"></i> -->
                    </el-button>

                    <el-tooltip effect="dark"
                                content="添加格"
                                :enterable='false'
                                placement="top">
                      <el-button plain
                                 size='mini'
                                 @click="showAddGrid(item.code,index_2,index_3,item_2.children[index_3].children?item_2.children[index_3].children[item_2.children[index_3].children.length-1].code:0,item_3)"
                                 :key='index_2+index_3+"addg"'
                                 class="add_grid">
                        <i class='el-icon-plus'></i>
                      </el-button>
                    </el-tooltip>
                  </div>

                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
import { postCodeGetGoods, postWarehouseLocation, postArea, postHJ, postTier, postRattle, postWlnumCode, verificationCode } from '@/request/api'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
  data () {
    return {
      printObj: {
        id: "printJS-form",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      // ----------打印选择----------
      print_selected: {
        area: 0,
        hj: '',
        hjcode: []
      },
      // ----------打印默认显示----------
      first: 'first',
      // ----------打印类型----------
      skip_type: "",
      // ----------打印浏览----------
      printShow: false,
      // ----------是否显示打印----------
      print_show: false,

      // ----------加载----------
      fullscreenLoading: false,
      inputVisible: false,
      // ----------查询----------
      inquireData: {
        wlnum: '',
        hjcode: '',
      },
      // ----------是否显示区域设置----------
      areaSet: {
        show: false,
        data: {}
      },
      // ----------显示添加货架设置----------
      goodsAdd: {
        show: false,
        ckid: '',
        code: '',
        num: 0,
        pid: '',
        hjcode: '',
      },
      // ----------是否货架设置----------
      goodsSet: {
        show: false,
        data: {}
      },
      // ----------是否显示层添加----------
      tierAdd: {
        show: false,
        ckid: '',
        code: '',
        num: 0,
        pid: '',
        hjcode: '',
      },
      // ----------是否显示格子添加----------
      gridAdd: {
        show: false,
        ckid: '',
        code: '',
        num: 0,
        pid: '',
        hjcode: '',
      },
      // ----------是否显示格子管理----------
      gridAdmin: {
        show: false,
        data: {}
      },
      // ----------局部加载----------
      loading: false,
      // ----------默认选中的货架----------
      defuend_tabs: '1test',
      areaActive: '0area',
      // ----------仓库数据----------
      warehouseData: [],
      // ----------自动生成区域弹框----------
      autoPop: false,
      // ----------自动生成区域弹框数据----------
      formData: {
        area: '',
        number: 1,
        tier: 1,
        grid: 1,
        info: ''
      },
      wid: '',
    }
  },
  methods: {
    //-------条形码-------
    barcode (id) {
      this.$nextTick(() => {
        var JsBarcode = require('jsbarcode')
        JsBarcode('#' + id, id, {
          format: 'CODE128',//选择要使用的条形码类型
          displayValue: true,//是否在条形码下方显示文字
          width: '2px', //条宽度
          height: '50px', //高度
          font: 'Play',//设置文本的字体
          margin: 4//设置条形码周围的空白边距
        })
      })
    },
    //---------取消打印-------
    cancelPrint () {
      this.printShow = false
      this.skip_type = ''
    },
    //---------显示打印内容-------
    print_state_show (type) {

      if (type == 'selectedGrid') {
        for (const key in this.print_selected.grid) {
          this.selectedTier.push({
            c: this.print_selected.grid[key].split("-")[0],
            g: this.print_selected.grid[key].split("-")[1]
          })
        }
      }

      this.printShow = true
      this.skip_type = type
    },
    //  -------显示打印-------
    showPrint () {
      this.print_show = true
    },
    //  -------货架码获取快递订单信息------- 
    async hjcodeGetGoods (hjcode) {
      const res = await postCodeGetGoods({
        ckid: this.wid,
        hjcode
      })
      if (res.errno == 1) {
        console.log(this.$message.error(res.message));
      }
    },
    //   -------物流单号查询货架-------
    async wlnumCode () {
      let res = await postWlnumCode({
        wlnum: this.inquireData.wlnum
      })
      if (res.errno == 1) {
        this.$message.error(res.message)
      }

    },
    //   -------货架号查询-------
    async hjcodeInquire () {
      let codeArr = this.inquireData.hjcode.split('-')
      if (codeArr.length !== 4) {
        this.$message.error('请输入正确的货架码')
      } else {
        let res = await verificationCode({
          ckid: this.wid,
          hjcode: this.inquireData.hjcode
        })
        if (res.errno == 0) {
          this.areaActive = codeArr[0]
          this.defuend_tabs = codeArr[1] + 'test'
          this.$message.success('查找成功')
        } else {
          this.$message.error(res.message)
        }
      }
    },
    //   -------删除区域-------
    dalArea () {
      this.fullscreenLoading = true
      this.$confirm('此操作将永久删除该区域, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await postArea({
          pd: 'del',
          ckid: this.areaSet.data.ckid,
          id: this.areaSet.data.id
        })
        if (res.errno == 0) {
          this.$message.success(res.message)
          this.warehouseLocation()
          this.areaActive = this.warehouseData[0].code
          this.areaSet.show = false
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      this.fullscreenLoading = false
    },
    //   -------显示设置区域-------
    showSetArea (item) {
      this.areaSet.show = true;
      this.areaSet.data.ckid = item.ckid;
      this.areaSet.data.code = item.code;
      this.areaSet.data.id = item.id;
      this.areaSet.data.info = item.info;
      this.areaSet.data.num = parseInt(item.num, 10);
      this.areaSet.data.pid = item.pid;
      this.areaSet.data.info = item.info;
    },

    //   -------修改区域备注-------
    async handleInputConfirm (item) {
      let items = item || this.areaSet.data
      this.inputVisible = true;

      let res = await postArea({
        pd: 'edit',
        ...items
      })
      if (res.errno == 0) {
        this.$message.success('修改成功')
        this.warehouseLocation()
      } else {
        this.$message.errno('修改失败')
      }
      if (!item) {
        this.areaSet.show = false;
      }
      this.inputVisible = false;
    },
    //-----切换为输入框，更改区域说明-------
    showInput () {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //   ------清除货架数据------
    closeGoodsAdd () {
      this.goodsAdd = {
        show: false,
        ckid: '',
        code: '',
        num: 0,
        pid: '',
        hjcode: ''
      }
    },
    //   ------显示添加货架------
    showAddGoods (code, pid, fIndex) {
      this.goodsAdd.show = true
      this.goodsAdd.code = parseInt(code, 10) + 1
      this.goodsAdd.pid = pid
      this.goodsAdd.findex = fIndex
    },
    //   ------添加货架------
    async addGoods () {
      this.loading = true
      let res = await postHJ({
        pd: 'add',
        ckid: this.wid,
        code: this.goodsAdd.code,
        pid: this.goodsAdd.pid
      })
      if (res.errno == 0) {
        this.warehouseLocation().then(() => {
          this.defuend_tabs = this.warehouseData[this.goodsAdd.findex].children.length + 'test'
          this.goodsAdd.show = false
        })
      } else {
        this.$message.error(res.message)
      }
      this.loading = false
    },
    //   ------删除货架------
    async dalGoods () {
      this.loading = true
      let res = await postHJ({
        pd: 'del',
        ckid: this.goodsSet.data.ckid,
        id: this.goodsSet.data.id
      })
      if (res.errno == 0) {
        this.$message.success(res.message)
        this.warehouseLocation()
        this.defuend_tabs = '1test'
        this.goodsSet.show = false
      } else {
        this.$message.error(res.message)
      }
      this.loading = false
    },
    //   ------修改货架------
    async editGoods () {
      let res = await postHJ({
        pd: 'edit',
        ckid: this.goodsSet.data.ckid,
        id: this.goodsSet.data.id,
        code: this.goodsSet.data.code,
        num: this.goodsSet.data.num,
        pid: this.goodsSet.data.pid,
      })
      if (res.errno == 0) {
        this.$message.success(res.message)
        this.warehouseLocation()

        this.goodsSet.show = false
      } else {
        this.$message.error(res.message)
      }
      this.loading = false
    },

    //   ------设置内容------
    setContent (item_2) {
      this.goodsSet.show = true;
      this.goodsSet.data.ckid = item_2.ckid;
      this.goodsSet.data.code = item_2.code;
      this.goodsSet.data.id = item_2.id;
      this.goodsSet.data.info = item_2.info;
      this.goodsSet.data.num = parseInt(item_2.num, 10);
      this.goodsSet.data.pid = item_2.pid;
    },
    //   ------清除数据------
    closeTierAdd () {
      this.tierAdd = {
        show: false,
        ckid: '',
        code: '',
        num: 0,
        pid: '',
        hjcode: ''
      }
    },
    //   ------添加层------
    async addTier () {
      this.loading = true
      let res = await postTier({
        pd: 'add',
        ckid: this.wid,
        code: this.tierAdd.code,
        num: this.tierAdd.num,
        pid: this.tierAdd.pid,
      })
      if (res.errno == 0) {
        this.$message.success(res.message)
        this.warehouseLocation()

        this.tierAdd.show = false
      } else {
        this.$message.error(res.message)
      }
      this.loading = false

    },
    //   ------显示添加层------
    showAddTier (pid, code) {
      this.tierAdd.pid = pid
      this.tierAdd.code = parseInt(code, 10) + 1
      this.tierAdd.show = true
    },
    //   ------清除数据------
    closeGridAdd () {
      this.gridAdd = {
        show: false,
        ckid: '',
        code: '',
        num: 0,
        pid: '',
        hjcode: ''
      }
    },
    //   ------添加格子------
    async addGrid () {
      this.loading = true
      let res = await postRattle({
        pd: 'add',
        ckid: this.wid,
        code: this.gridAdd.code,
        num: this.gridAdd.num,
        pid: this.gridAdd.pid,
        hjcode: this.gridAdd.hjcode
      })
      if (res.errno == 0) {
        this.$message.success(res.message)
        this.warehouseLocation()

        this.gridAdd.show = false
      } else {
        this.$message.error(res.message)
      }
      this.loading = false

    },
    //   ------显示添加格子------
    showAddGrid (index1, index2, index3, index4, item) {
      this.gridAdd.hjcode = `${index1}-${index2 + 1}-${index3 + 1}-${parseInt(index4, 10) + 1}`
      this.gridAdd.pid = item.id
      this.gridAdd.code = parseInt(index4, 10) + 1
      this.gridAdd.show = true
    },
    //   ------编辑格子------
    async gridEdit () {
      this.loading = true
      let res = await postRattle({
        pd: 'edit',
        ckid: this.gridAdmin.data.ckid,
        id: this.gridAdmin.data.id,
        code: this.gridAdmin.data.code,
        num: this.gridAdmin.data.num,
        pid: this.gridAdmin.data.pid,
        hjcode: this.gridAdmin.data.hjcode
      })
      if (res.errno == 0) {
        this.$message.success(res.message)
        this.warehouseLocation()

        this.gridAdmin.show = false
      } else {
        this.$message.error(res.message)
      }
      this.loading = false
    },
    //   ------删除格子------
    removeGrid () {
      this.loading = true
      this.$confirm('此操作将永久删除该货格，不可逆转！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await postRattle({
          pd: 'del',
          ckid: this.gridAdmin.data.ckid,
          id: this.gridAdmin.data.id,
        })
        if (res.errno == 0) {
          this.$message.success(res.message)
          this.warehouseLocation()
          this.gridAdmin.show = false
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

      this.loading = false


    },
    //   ------显示格子------
    show_grid (e) {
      this.gridAdmin.data = e
      this.gridAdmin.data.num = parseInt(this.gridAdmin.data.num, 10)
      this.gridAdmin.show = true
    },
    //   ------返回对应值------
    returnCorresponding (data, index, flag) {
      if (flag) {
        if ((index + 1) % 2 == 0) {
          return 'odd_btn'
        }
      }

      switch (data) {
        case '1':
          return 'normalData'
        case '2':
          return 'warningData'
        case '3':
          return 'overData'
        default:
          if ((index + 1) % 2 == 0) {
            return 'odd_btn'
          }

      }
    },
    //   ------开始循环生成------
    show_new_freight_space () {

    },
    //   ------开始循环生成------
    active_label () {

    },
    //   ------开始循环生成------
    async startCycle () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      let area_res = await postArea({
        pd: 'add',
        ckid: this.wid,
        code: this.formData.area,
        info: this.formData.info,
      })
      if (area_res.errno == 0) {
        let denominator = ((this.formData.number * this.formData.tier) * this.formData.grid) + (this.formData.number * this.formData.tier) + this.formData.number
        let member = 0
        for (let key = 1; key <= this.formData.number; key++) {
          let hj_res = await postHJ({
            pd: 'add',
            ckid: this.wid,
            code: key,
            pid: area_res.id,
          })
          if (hj_res.errno == 0) {
            member++
            loading.setText(((member / denominator) * 100).toFixed(2) + "%")
            for (let k = 1; k <= this.formData.tier; k++) {
              let tier_res = await postTier({
                pd: 'add',
                ckid: this.wid,
                code: k,
                pid: hj_res.id,
              })
              if (tier_res.errno == 0) {
                member++
                loading.setText(((member / denominator) * 100).toFixed(2) + "%")
                for (let ke = 1; ke <= this.formData.grid; ke++) {
                  let grid_res = await postRattle({
                    pd: 'add',
                    ckid: this.wid,
                    code: ke,
                    hjcode: `${this.formData.area}-${key}-${k}-${ke}`,
                    pid: tier_res.id
                  })
                  if (grid_res.errno == 0) {
                    member++
                    loading.setText(((member / denominator) * 100).toFixed(2) + "%")
                  }
                }
              }
            }
          }
        }
        this.autoPop = false
        this.fullscreenLoading = false
        this.warehouseLocation().then(() => {
          this.areaActive = this.warehouseData[this.warehouseData.length - 1].code
        })
      }
      loading.close();
    },

    //   ------检查数据是否完整------
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.startCycle()
        } else {
          return false;
        }
      });
    },
    //   ------获取仓位信息-------
    async warehouseLocation () {
      this.loading = true
      let res = await postWarehouseLocation({
        ckid: this.wid
      })
      if (res.errno == 0) {
        this.warehouseData = res.gsinfo
      } else {
        this.$message.error(res.message)
      }
      this.loading = false
    }
  },
  computed: {

  },
  components: {
    vueQr
  }
  ,
  async created () {
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    //  --------- 
    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.warehouseLocation().then(() => {
        this.areaActive = this.warehouseData[0].code
      })
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', this.$store.state.warehouse)
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.warehouseLocation().then(() => {
        this.areaActive = this.warehouseData[0].code
      })
    }
  }
}
</script>

<style lang='scss'>
.printTier {
  page-break-after: always;
  width: 100mm;
  height: 100mm;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   transform: scale(0.7);
  .printTier_top,
  .printTier_top_hj {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40mm;
  }
  .printTier_top_hj {
    height: 100%;
    font-size: 20mm;
  }
  .printTier_bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.print_main_view {
  .printTier {
    // width: 100mm;
    // height: 100mm;
    // background-color: #fff;
    // display: flex;
    // flex-direction: column;
    // page-break-after: always;

    .item_top {
      flex: 1;
      font-size: 14mm;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item_main {
      flex: 1;
      padding: 10px 0;
      box-sizing: border-box;
      border-top: 1px solid #333;
      border-bottom: 1px solid #333;
      display: flex;
      .main_left {
        flex: 1;
        // background: pink;
        border-right: 1px solid #333;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 6mm;
        // padding: 0 13mm;
        box-sizing: border-box;
        flex-direction: column;
      }
      .main_right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .item_bottom {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      //   background: rgb(76, 45, 88);
    }
  }
}
.positions {
  width: 100%;
  height: 100%;
  #selectGrid {
    border: 1px solid #4e91fd !important;
    background-color: #4e91fd;
    color: #fff;
    box-shadow: 0 2px 12px 0 rgba(78, 145, 253, 0.521);
  }

  .print_main {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(56, 56, 56, 0.767);
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    .print_main_top {
      flex: 1;
      width: 100%;
      max-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      //   background-color: #fff;
    }
    .print_main_view {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      overflow: auto;
      .print_main_c {
        display: flex;
        flex-wrap: wrap;
      }
      .print_main_hj {
        display: flex;
        flex-wrap: wrap;
      }
      .printTier {
        margin: 0 20px;
        margin-bottom: 10px;
        width: 100mm;
        //   height: ;
      }
    }
  }
  .prin_dialog {
    min-width: 1000px;
  }
  .printView {
    min-height: 700px;
    // min-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .print_select_code {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .el-checkbox {
      margin-left: 0;
      margin: 0;
    }
    .print_select_area {
      display: flex;
      width: 100%;
      //   margin-top: 20px;
      //   justify-content: center;
      .el-radio {
        width: 50px;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
      }
      .el-radio__label {
        font-size: 18px;
        font-weight: 700;
        padding: 0;
        padding-bottom: 10px;
      }
    }
  }
  .grid_pop_view {
    .el-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 50%;
        box-sizing: border-box;
        padding: 0 10px;
      }
    }
  }
  .demo-ruleForm {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
    }
    .el-form-item__label {
      width: 130px;
    }
    .el-form-item__error {
      //   margin-left: 130px;
    }
    .el-input-number,
    .el-input {
      width: 100px;
    }
  }
  .positions_view {
    width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // align-items;
    padding-bottom: 20px;
    box-sizing: border-box;
    overflow: auto;
    .positions_view_top {
      width: 90%;
      flex: 1;
      max-height: 150px;
      min-height: 150px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin-top: 4px;
      .positions_view_inqure,
      .positions_view_btn {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        .el-form {
          margin: 0;
        }
        .el-input {
          width: 200px;
        }
        .form_view {
          display: flex;
          .el-button {
            margin-left: 4px;
          }
        }
        .el-form-item {
          margin-bottom: 0;
        }
      }
      .positions_view_inqure {
        // background-color: pink;
      }
      .positions_view_btn {
        // background-color: rgb(192, 255, 208);
      }
    }
    .right_set:hover {
      color: #4c90fd;
    }
    .right_set {
      //   margin-left: 4px;
      position: relative;
      left: 5px;
      color: #303133;
    }
    .freightSpace_main_area {
      width: 98%;
      height: 100%;
      box-sizing: border-box;
      margin: 10px;
      .el-alert,
      .input-new-tag {
        width: 100%;
        height: 60px;

        .el-input__inner {
          height: 100%;
          border: 0;
          background-color: #fdf6ec;
          color: #e6a23c;
        }
      }
      .dataJson_null {
        width: 100%;
        height: 300px;
        // display: flex;
        // align-content: center;
        // background-color: pink;
      }
      .add_grid {
        background-color: #67c23a;
        border: 0;
        color: #fff;
      }
      .odd_btn {
        background-color: #faf9f3;
      }

      .goods_shelf {
        width: 100%;
        .grid_data_class:hover {
          .delete_grid {
            display: flex;
          }
        }
        .goods_shelf_item {
          margin-top: 20px;
          //   height: 50px;
          overflow-y: hidden;
          overflow-x: auto;
          white-space: nowrap;
          .grid_data_class {
            position: relative;
          }
          .delete_grid {
            position: absolute;
            top: 0;
            color: #333;
            background-color: rgba(255, 192, 203, 0);
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 8px;
            transition: all 0.3s;
            display: none;
          }
          .delete_grid:hover {
            color: #f56c6c;
            background-color: #f5f7fa;
          }
        }
      }
      .el-tabs__content {
        height: 100%;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .positions_list_null {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      // background-color: pink;
    }
    .warehouseData_view {
      flex: 1;
      width: 90%;
      margin-top: 20px;
      //   height: 90%;
      //   border: 0;
      .el-tabs__nav-wrap {
        width: 93%;
      }
      .el-tabs__new-tab {
        margin-right: 20px;
        background-color: #67c23a;
        color: #fff;
        border: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
      }
      .el-tab-pane {
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
      }
      .el-tabs__item {
        min-width: 100px;
        min-height: 50px;
        line-height: 50px;
        text-align: center;
      }
    }
  }
}
</style>